@font-face {
  font-family: 'greenfly-fan-engagement-app';
  src:
    url('fonts/greenfly-fan-engagement-app.ttf?8fjn21') format('truetype'),
    url('fonts/greenfly-fan-engagement-app.woff?8fjn21') format('woff'),
    url('fonts/greenfly-fan-engagement-app.svg?8fjn21#greenfly-fan-engagement-app') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="gf-"], [class*=" gf-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'greenfly-fan-engagement-app' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gf-timer:before {
  content: "\e905";
}
.gf-image:before {
  content: "\e900";
}
.gf-video:before {
  content: "\e901";
}
.gf-portrait:before {
  content: "\e902";
}
.gf-landscape:before {
  content: "\e903";
}
.gf-fly:before {
  content: "\e904";
}
